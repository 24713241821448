<template>
    <div>
        <f-default-header
			title="Notificações"
            subtitle="Listagem" />

        <div
            class="alert-list-item"
            :class="{active:item.status=='opened'}"
            v-for="(item,index) in alerts"
            :key="index">
            <strong>#{{item.ref_id}}</strong><br />
            {{getTitle(item.code)}}
            <hr>
            <div class="actions">
                <div class="ali-act" @click="viewOrder(item.ref_id)">ver pedido</div>
                <!-- <div class="ali-act" @click="markRead(item.id)">marcar como lida</div> -->
                <div class="ali-act ali-remove" @click="remove(item.id)">remover</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        alerts() {
            return this.$store.state.alert.list
        }
    },
    methods: {
        markRead( id ) {
            var it = this.alerts.find( item => item.id == id )
            it.status = 'sleeping'
            this.$store.dispatch( 'alert/markAsRead', id )
        },
        remove( id ) {
            this.$store.dispatch( 'alert/remove', id )
        },
        getTitle( str ){
            var message
            switch (str) {
                case 'order-ended':
                    message = 'Pedido será processado manualmente, contate a Fluke para mais informações ou aguarde o contato'
                    break;

                case 'order-waiting':
                    message = 'Pedido necessitando alteração. Por favor ver pedido e ajuste os dados.'
                    break;
            
                default:
                    message = 'Notificação mal formatada. '
                    break;
            }
            return message
        },
        viewOrder(id){
            this.$router.push({
                name: 'order-view',
                params: {
                    id
                }
            })
        }
    }
}
</script>

<style lang="scss">
.alert-list-item {
    background-color: #fff;
    padding: 10px 15px;
    margin-bottom: 14px;
    border-radius: 3px;
    border-left: #a3a3a3 solid 10px;

    &.active {
        border-left: #be0000 solid 10px;
    }

    hr {
        margin: 15px 0;
    }

    .actions {
        text-align: right;
        display: flex;
        justify-content: flex-end;

        .ali-act {
            background-color: #f5f5f5;
            padding: 3px 7px;
            margin-bottom: 10px;
            cursor: pointer;
            margin-left: 10px;
        }

        .ali-remove {
            //
        }
    }
}
</style>